$(document).ready(function () {
    $("#contact_form").submit(function (event) {
        var $form = $(this);
        event.preventDefault();

        var submit_button = $form.find('[type="submit"]');
        submit_button.prop("disabled", true);

        $('.js-errors').html('').hide();
        $('.js-success').html('').hide();

        var formData = $form.serialize();

        $.post(routes['contact_form_submit'], formData, function (response) {
            if (response.success == 0) {
                var target = $('.js-popup-content');
                target.html('');
                target.append('<div class="alert">' + response.message + '</div>');
            } else {
                $('#contact_form')[0].reset();
                var target = $('.js-popup-content');
                target.html('');
                target.append('<div class="alert">' + response.message + '</div>');
            }


        Fancybox.show([{ src: "#responsePopupContact", type: "inline", width: 500, height: 200 }]);
        }).fail(function (error) {
            var target = $('.js-popup-content');
            target.html('');
            target.append('<div class="alert">' + texts['contact']['feedback_ko'] + '</div>');
        }).always(function () {
            submit_button.prop("disabled", false);
        });
    });
});
