//-------------------------------------------
// start register
//-------------------------------------------
$(document).ready(function(){
    $(".toggle-password").click(function() {
        $(this).toggleClass("field-icon--on");
        var input = $($(this).attr("toggle"));
        if (input.attr("type") == "password") {
        input.attr("type", "text");
        } else {
        input.attr("type", "password");
        }
    });
});

$(document).ready(function(){
  $('[name="province_id"]').change(function(){
      $.post( routes['authentication_ajax_cities'], { 'province_id':$(this).val(), '_token': $('form input[name="_token"]').val() }, function( data ) {
          $("[name='city_id']").html('');
          $("[name='city_id']").append('<option value="" selected></option>');
          for(var i=0;i<data.length;i++) {
              $("[name='city_id']").append('<option value='+ data[i].id +'>'+data[i].name+ '</option>');
          }

      });
  });
});

$("#register_form").on("submit", function (e) {

  e.preventDefault();
  $form = $(this);
  $btn = $form.find('[type="submit"]');
  $btn.prop("disabled", true);

  var formData = $form.serialize();

  $.post( routes['authentication_register_submit'], formData, function( response ) {
      var message = response.message;
      if(response.success == 1) {
          showFeedback(response.message, 'success');
          $form.trigger('reset');
          $('.js-form-wrapper').hide();

      }else{
          showFeedback(response.message, 'warning');
      }
  })
  .fail(function(error) {
      if (error.status == 422) {
          var data = error.responseJSON;
          showFeedback(data.message, 'danger', false, data.errors);
      } else {
          showFeedback(texts['authentication']['feedback_general_error'], 'danger');
      }
  })
  .always(function() {
      $btn.prop("disabled", false);
  })

});

//-------------------------------------------
// end register
//-------------------------------------------

//-------------------------------------------
// start login
//-------------------------------------------
$("#login_form").on("submit", function (e) {

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);
    formData = $form.serialize();

    $.post(routes['authentication_login_submit'], formData, function( response ) {
        if (response.success == 1) {
            parent.location.reload(true);
        } else {
            showFeedback(response.message, 'warning');
        }
    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            showFeedback(data.message, 'danger', false, data.errors);
        } else {
            showFeedback(texts['authentication']['feedback_general_error'], 'danger');
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })

})
//-------------------------------------------
// end login
//-------------------------------------------

//-------------------------------------------
// start forgot password
//-------------------------------------------
$("#password_form").on("submit", function (e) {

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);

    $.post( routes['authentication_forgot_password_submit'],{
        email: $form.find("[name='forgot_password_email']").val(),
        _token: $form.find("[name='_token']").val()
    }, function (response) {

        if(response.success == 1) {
            showFeedback(response.message, 'success');
            $form.trigger('reset');
            $('.js-form-wrapper').hide();

        }else{
            showFeedback(response.message, 'warning');
        }


    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            showFeedback(data.message, 'danger', false, data.errors);
        } else {
            showFeedback("@lang('front/authentication.feedback.general_error')", 'danger');
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })
})

//-------------------------------------------
// end forgot password
//-------------------------------------------

//-------------------------------------------
// start reset password
//-------------------------------------------

$(".toggle-passwordone").click(function() {

    $(this).toggleClass(".field-icon--on");
    var input = $($(this).attr("toggle"));
    if (input.attr("type") == "password") {
        input.attr("type", "text");
    } else {
        input.attr("type", "password");
    }
});

$("#pass_form").on("submit", function (e) {

    e.preventDefault();
    $form = $(this);
    $btn = $form.find('[type="submit"]');
    $btn.prop("disabled", true);
    formData = $form.serialize();

    $.post( routes['authentication_reset_password_submit'], formData, function (response) {
        console.log(response);
        if(response.success == 1) {
            showFeedback(response.message, 'success', false);
            $form.trigger('reset');
            $('.js-form-wrapper').hide();
        }else{
            showFeedback(response.message, 'warning', false);
        }
    })
    .fail(function(error) {
        if (error.status == 422) {
            var data = error.responseJSON;
            showFeedback(data.message, 'danger', false, data.errors);
        } else {
            showFeedback(texts['authentication']['feedback_general_error'], 'danger');
        }
    })
    .always(function() {
        $btn.prop("disabled", false);
    })
})

//-------------------------------------------
// end reset password
//-------------------------------------------
