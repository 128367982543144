jQuery(document).ready(function(){
	jQuery('.menu-toggle').click(function(){
		jQuery(this).toggleClass('open');
		jQuery('.main-navigation').toggleClass('open');
		jQuery('.header').toggleClass('open');
	});
});

var menuToggle = $('.m-toggle');
var menuHamb = $('.m-hamb');
var menuHambClose = $('.m-hamb-close');
var body = $('body');

$(menuToggle).click(function(){
  // $(this).toggleClass('js-open');
  $(menuHamb).addClass('js-show');
  $(body).addClass('blocked');
});

$(menuHambClose).click(function(){
  $(menuHamb).removeClass('js-show');
  $(body).removeClass('blocked');
});


var subMenu = $('.submenu i');
var menuSubMenu = $('.menu__submenu');

$(subMenu).click(function(){
  $(this).toggleClass('js-menu');
  // $(menuSubMenu).toggleClass('js-menu');
  var subMenuIndex = $(this).data('submenu');
  console.log(subMenuIndex);
  $('.menu__submenu--'+subMenuIndex+'').toggleClass('js-menu');
});