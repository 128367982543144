$(document).ready(function () {
    $("#contact_work_form").submit(function (event) {
        var $form = $(this);
        event.preventDefault();

        var submit_button = $form.find('[type="submit"]');
        submit_button.prop("disabled", true);

        $('.js-errors-work').html('').hide();
        $('.js-success-work').html('').hide();

        var formData = new FormData($form[0]);  // Crea el objeto FormData con todo el formulario

        // Agrega manualmente el campo de archivo al objeto FormData
        var attachmentInput = document.getElementById('attachment');
        if (attachmentInput.files.length > 0) {
            formData.append('attachment', attachmentInput.files[0]);
        }

        $.ajax({
            type: "POST",
            url: routes['contact_work_form_submit'],
            data: formData,
            processData: false,  // Importante para enviar archivos
            contentType: false,  // Importante para enviar archivos
            success: function (response) {
                $('#contact_work_form')[0].reset();
                console.log(response.message);
                var target = $('.js-popup-content');
                target.html('');
                target.append('<div class="alert">' + response.message + '</div>');

                // Abre el popup con Fancybox
                Fancybox.show([{ src: "#responsePopupContact", type: "inline" , width: 500, height: 200 }]);
            },
            error: function (error) {
                var target = $('.js-popup-content');
                target.html('');
                target.append('<div class="alert">' + texts['contact']['feedback_ko'] + '</div>');

                // Abre el popup con Fancybox
                Fancybox.show([{ src: "#responsePopupContact", type: "inline" }]);
            },
            complete: function () {
                submit_button.prop("disabled", false);
            }
        });
    });
});
